const firebaseConfig = {
  apiKey: "AIzaSyAMzeY9zyv6IvloQ599Tzs8YNkNlsLaagw",
  authDomain: "payee-development.firebaseapp.com",
  databaseUrl: "https://payee-development.firebaseio.com",
  projectId: "payee-development",
  storageBucket: "payee-development.appspot.com" ,
  messagingSenderId: "28219729377",
  appId: "1:28219729377:web:daa0db9ab27fed652f5ea0",
  measurementId: "G-TQ3772QS86",
}
export default firebaseConfig;
